import React, { useEffect } from "react";

import { RootState } from "../../common/store";
import { useDispatch, useSelector } from "react-redux";
import {
  CalendarState,
} from "../../common/store/calendar/calendar.reducer";
import WeeklyView from "./WeeklyView";
import "./Planning.scss";

import { fetchPlanning } from "../../common/store/calendar/calendar.actions";

interface PlanningProps {}

type PartialCalendarState = Pick<
  CalendarState,
  "currentDate"  | "loading" | "weeklyPlanning"
>;

const Planning: React.FunctionComponent<PlanningProps> = () => {
  const dispatch = useDispatch();

  const {
    currentDate: date,
    loading: planningLoading,
    weeklyPlanning,
  }: PartialCalendarState = useSelector((state: RootState) => state.calendar);

  const appInit: boolean = useSelector(
    (state: RootState) => state.global.appInit
  );

  const init = async () => {
    dispatch(fetchPlanning());
  };

  useEffect(() => {
    if (!appInit) {
      init();
    }
  }, [ date, appInit]);

  return (
    <div className="planning-container">
        <WeeklyView
          weeklyPlanning={weeklyPlanning}
          currentDate={date}
          loadingStatus={planningLoading}
        />
    </div>
  );
};

export default Planning;
