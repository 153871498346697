import {
  CalendarActionTypes,
  ToggleModalAction,
  IncrementDateAction,
  DecrementDateAction,
  SelectDateAction,
  ToggleDuplicationModeAction,
  UpdateRecordToDuplicateListAction,
} from "./calendar.types";
import { PlanningFilters } from "./calendar.reducer";
import { CreatePlanningRecords, FetchPlanning } from "../../../helpers/Api";
import dayjs from "dayjs";
import { store } from "../../../App";
import {
  buildDailyData,
  chunks,
  filterDay,
  prepareDataToDuplicate,
} from "../../utils";

export function incrementDate(): IncrementDateAction {
  return {
    type: CalendarActionTypes.INCREMENT_DATE,
  };
}

export function decrementDate(): DecrementDateAction {
  return {
    type: CalendarActionTypes.DECREMENT_DATE,
  };
}

export function selectDate(date: Date): SelectDateAction {
  return {
    type: CalendarActionTypes.SELECT_DATE,
    payload: dayjs(date),
  };
}

export function toggleModal(
  showModal: boolean,
  record: any
): ToggleModalAction {
  return {
    type: CalendarActionTypes.TOGGLE_MODAL,
    payload: { modalState: showModal, record },
  };
}

export function fetchPlanning() {
  return async function (dispatch) {
    dispatch({ type: CalendarActionTypes.FETCH_PLANNING_REQUEST });
    const state = store.getState();
    const { currentDate: date } = state.calendar;
    const weeklyPlanning = await FetchPlanning(date);
    const weekFirstDay = date.weekday(0);
    const planningSorted = [[], [], [], [], [], [], []];
    await weeklyPlanning.map(async (record) => {
      for (let i = 0; i < 7; i++) {
        // weekFirstDay.weekday(i) utilise la locale FR donc weekFirstDay.weekday(0) correspond au lundi, premier jour de la semaine
        if (dayjs(record.fields.Date).isSame(weekFirstDay.weekday(i), "day")) {
          const data = await buildDailyData(record, state);
          planningSorted[i].push(data);
        }
      }
    });

    dispatch({
      type: CalendarActionTypes.FETCH_PLANNING_SUCCESS,
      payload: { weeklyPlanning: planningSorted, dailyPlanning: [] },
    });
    dispatch(setPlanningFilters(state.calendar.filters));

    // Si on a la modal d'ouverte, on met à jour le record qui se trouve dedans
    if (state.calendar.currentRecord !== null) {
      const newState = store.getState();
      const allRecords = [
        ...newState.calendar.unfilteredWeeklyPlanning[0],
        ...newState.calendar.unfilteredWeeklyPlanning[1],
        ...newState.calendar.unfilteredWeeklyPlanning[2],
        ...newState.calendar.unfilteredWeeklyPlanning[3],
        ...newState.calendar.unfilteredWeeklyPlanning[4],
        ...newState.calendar.unfilteredWeeklyPlanning[5],
        ...newState.calendar.unfilteredWeeklyPlanning[6],
      ];
      const recordUpdated = await allRecords.find(
        (item) => item.id === state.calendar.currentRecord.id
      );
      dispatch(toggleModal(true, recordUpdated));
    }
  };
}

export const setPlanningFilters = (filters: Partial<PlanningFilters>) => {
  return async function (dispatch) {
    const currentFilters = store.getState().calendar.filters;
    const { unfilteredWeeklyPlanning } = store.getState().calendar;
    const newFilters = { ...currentFilters, ...filters };

    dispatch({
      type: CalendarActionTypes.SET_PLANNING_FILTERS,
      payload: newFilters,
    });
    const weeklyFiltered = await unfilteredWeeklyPlanning.map((day) =>
      filterDay(day, newFilters)
    );
    dispatch({
      type: CalendarActionTypes.FILTER_PLANNING,
      payload: {
        weeklyPlanning: weeklyFiltered,
        dailyPlanning: [],
      },
    });
  };
};

export const toggleFilterPanel = (toggle: boolean) => {
  return {
    type: CalendarActionTypes.TOGGLE_FILTER_PANEL,
    payload: toggle,
  };
};

export const resetDuplicationMode = () => {
  return {
    type: CalendarActionTypes.RESET_DUPLICATION_MODE,
  };
};
export const toggleDuplicationMode = (
  dayIndex: number,
  toggle: boolean
): ToggleDuplicationModeAction => {
  const { weeklyPlanning} = store.getState().calendar;
  let recordsToDuplicate = [];

  if (dayIndex !== null)
    recordsToDuplicate = Array.from(weeklyPlanning[dayIndex]);

  return {
    type: CalendarActionTypes.TOGGLE_DUPLICATION_MODE,
    payload: { dayIndex, duplicationMode: toggle, recordsToDuplicate },
  };
};

export const updateRecordListToDuplicate = (
  record: any,
  checked: boolean
): UpdateRecordToDuplicateListAction => {
  const recordsToDuplicate = store.getState().calendar.recordsToDuplicate;

  // on commence par filtrer la liste,
  // par précaution, pour ne pas ajouter 2 fois le record si jamais il y était déjà
  // c'est cette liste qui est renvoyée si l'action est de déselectionner le record
  const filteredRecordList = recordsToDuplicate.filter(
    (r) => r.id !== record.id
  );

  // Si le record qu'on veut ajouter est coché, on l'ajoute à la liste
  if (checked) filteredRecordList.push(record);

  return {
    type: CalendarActionTypes.UPDATE_RECORD_TO_DUPLICATE_LIST,
    payload: filteredRecordList,
  };
};

export const duplicateRecordList = (destinationDate, recordList?) => {
  return async function (dispatch) {
    let preparedList = [];
    if (recordList)
      preparedList = prepareDataToDuplicate(recordList, destinationDate);
    else {
      const recordsToDuplicate = store.getState().calendar.recordsToDuplicate;
      preparedList = prepareDataToDuplicate(
        recordsToDuplicate,
        destinationDate
      );
    }

    try {
      // On prépare un tableau de la forme [[rec1, rec2, ... rec10], [rec11, rec12, ... rec20]]
      // car airtable ne peut prendre que 10 items per request
      const slicedArray = [...chunks(preparedList, 10)];
      for (let arrayOfRecords of slicedArray) {
        await CreatePlanningRecords(arrayOfRecords);
      }
      dispatch(resetDuplicationMode());
    } catch (error) {}
    dispatch(fetchPlanning());
  };
};
