import dayjs, { Dayjs } from "dayjs";
import { CalendarActionTypes, CalendarAction } from "./calendar.types";

import "dayjs/locale/fr";
import weekday from "dayjs/plugin/weekday";
dayjs.Ls.fr.weekStart = 1;
dayjs.locale("fr");

dayjs.extend(weekday);

export interface PlanningFilters {
  customers: string[]; // array of ids
  drivers: string[]; // array of ids
  vehicles: string[]; // array of ids
  booking: boolean;
}

export interface CalendarState {
  currentDate: dayjs.Dayjs;
  showPlanningModal: boolean;
  currentRecord: null | any;
  weeklyPlanning: any[];
  unfilteredWeeklyPlanning: any[];
  loading: boolean;
  error: string;
  filters: PlanningFilters;
  isFilterPanelToggled: boolean;
  //Duplication Feature
  duplicationMode: boolean;
  recordsToDuplicate: any[];
  dayIndexToDuplicate: number | null;
}
export const initialCalendarState: CalendarState = {
  currentDate: dayjs(),
  showPlanningModal: false,
  currentRecord: null,
  weeklyPlanning: [[], [], [], [], [], [], []],
  unfilteredWeeklyPlanning: [],
  loading: true,
  error: "",
  filters: {
    customers: [],
    drivers: [],
    vehicles: [],
    booking: false,
  },
  isFilterPanelToggled: false,
  // Duplication Feature
  duplicationMode: false,

  // On va stocker temporairement les records que l'on va dupliquer
  // on leur appliquera un traitement (changer  la date) avant de les update coté serveur
  recordsToDuplicate: [],
  dayIndexToDuplicate: null,
};

export const calendarReducer = (
  state: CalendarState = initialCalendarState,
  action: CalendarAction
): CalendarState => {
  switch (action.type) {
    case CalendarActionTypes.INCREMENT_DATE: {
      let newDate = state.currentDate;
        newDate = newDate.add(1, "w");
      
      return {
        ...state,
        currentDate: newDate,
      };
    }
    case CalendarActionTypes.DECREMENT_DATE: {
      let newDate = state.currentDate;
      
        newDate = newDate.subtract(1, "w");
      

      return {
        ...state,
        currentDate: newDate,
        // Reset Duplication mode
        duplicationMode: false,
        dayIndexToDuplicate: null,
        recordsToDuplicate: [],
      };
    }

    case CalendarActionTypes.SELECT_DATE: {
      return {
        ...state,
        currentDate: action.payload,
        // Reset Duplication mode
        duplicationMode: false,
        dayIndexToDuplicate: null,
        recordsToDuplicate: [],
      };
    }

    case CalendarActionTypes.TOGGLE_MODAL: {
      return {
        ...state,
        showPlanningModal: action.payload.modalState,
        currentRecord: action.payload.record,
      };
    }

    case CalendarActionTypes.FETCH_PLANNING_REQUEST: {
      return {
        ...state,
        loading: true,
        error: "",
      };
    }
    case CalendarActionTypes.FETCH_PLANNING_SUCCESS: {
      return {
        ...state,
        loading: false,
        error: "",
        unfilteredWeeklyPlanning: action.payload.weeklyPlanning
      };
    }

    case CalendarActionTypes.FETCH_PLANNING_FAILURE: {
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      };
    }

    case CalendarActionTypes.SET_PLANNING_FILTERS: {
      return {
        ...state,
        filters: action.payload,
        // Reset Duplication mode
        duplicationMode: false,
        dayIndexToDuplicate: null,
        recordsToDuplicate: [],
      };
    }

    case CalendarActionTypes.FILTER_PLANNING: {
      return {
        ...state,
        loading: false,
        error: "",
        weeklyPlanning: action.payload.weeklyPlanning,
      };
    }

    case CalendarActionTypes.TOGGLE_FILTER_PANEL: {
      return {
        ...state,
        isFilterPanelToggled: action.payload,
      };
    }
    case CalendarActionTypes.TOGGLE_DUPLICATION_MODE: {
      return {
        ...state,
        duplicationMode: action.payload.duplicationMode,
        dayIndexToDuplicate: action.payload.dayIndex,
        recordsToDuplicate: action.payload.recordsToDuplicate,
      };
    }
    case CalendarActionTypes.RESET_DUPLICATION_MODE: {
      return {
        ...state,
        duplicationMode: false,
        dayIndexToDuplicate: null,
        recordsToDuplicate: [],
      };
    }

    case CalendarActionTypes.UPDATE_RECORD_TO_DUPLICATE_LIST: {
      return {
        ...state,
        recordsToDuplicate: action.payload,
      };
    }
    default:
      return state;
  }
};
