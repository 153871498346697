// Hook

import { useState } from "react";

export const useHover = (): [
  boolean,
  { onMouseEnter: () => void; onMouseLeave: () => void }
] => {
  const [isHovered, set] = useState(false);
  const binder = {
    onMouseEnter: (): void => set(true),
    onMouseLeave: (): void => set(false),
  };
  return [isHovered, binder];
};
