import React from "react";
import { IonLabel, IonSkeletonText, IonItem } from "@ionic/react";

const PlanningSlotLoader: React.FunctionComponent = () => {
  return (
    <IonItem>
      <IonLabel>
        <IonSkeletonText
          animated
          style={{ width: `${Math.floor(Math.random() * 20) + 80}%` }}
        />
      </IonLabel>
    </IonItem>
  );
};
export default PlanningSlotLoader;
