import {  PlanningFilters } from "./calendar.reducer";
import { Dayjs } from "dayjs";

export enum CalendarActionTypes {
  INCREMENT_DATE = "@@calendar/INCREMENT_DATE",
  DECREMENT_DATE = "@@calendar/DECREMENT_DATE",
  SELECT_DATE = "@@calendar/SELECT_DATE",
  CHANGE_VIEW = "@@calendar/CHANGE_VIEW",
  TOGGLE_MODAL = "@@calendar/TOGGLE_MODAL",
  FETCH_PLANNING_REQUEST = "@@calendar/FETCH_PLANNING_REQUEST",
  FETCH_PLANNING_SUCCESS = "@@calendar/FETCH_PLANNING_SUCCESS",
  FETCH_PLANNING_FAILURE = "@@calendar/FETCH_PLANNING_FAILURE",
  SET_PLANNING_FILTERS = "@@calendar/SET_PLANNING_FILTERS",
  FILTER_PLANNING = "@@calendar/FILTER_PLANNING",
  TOGGLE_FILTER_PANEL = "@@calendar/TOGGLE_FILTER_PANEL",
  TOGGLE_DUPLICATION_MODE = "@@calendar/TOGGLE_DUPLICATION_MODE",
  RESET_DUPLICATION_MODE = "@@calendar/RESET_DUPLICATION_MODE",
  UPDATE_RECORD_TO_DUPLICATE_LIST = "@@calendar/UPDATE_RECORD_TO_DUPLICATE_LIST",
}

export interface IncrementDateAction {
  type: CalendarActionTypes.INCREMENT_DATE;
}
export interface DecrementDateAction {
  type: CalendarActionTypes.DECREMENT_DATE;
}

export interface SelectDateAction {
  type: CalendarActionTypes.SELECT_DATE;
  payload: Dayjs;
}


export interface FetchPlanningRequestAction {
  type: CalendarActionTypes.FETCH_PLANNING_REQUEST;
}
export interface FetchPlanningSuccessAction {
  type: CalendarActionTypes.FETCH_PLANNING_SUCCESS;
  payload: { weeklyPlanning: any[]; dailyPlanning: any[] };
}
export interface FetchPlanningFailureAction {
  type: CalendarActionTypes.FETCH_PLANNING_FAILURE;
  payload: { error: string };
}

export interface ToggleModalAction {
  type: CalendarActionTypes.TOGGLE_MODAL;
  payload: {
    modalState: boolean;
    record: any;
  };
}

export interface SetPlanningFiltersAction {
  type: CalendarActionTypes.SET_PLANNING_FILTERS;
  payload: PlanningFilters;
}

export interface FilterPlanningAction {
  type: CalendarActionTypes.FILTER_PLANNING;
  payload: { weeklyPlanning: any[]; dailyPlanning: any[] };
}

export interface ToggleFilterPanelAction {
  type: CalendarActionTypes.TOGGLE_FILTER_PANEL;
  payload: boolean;
}

export interface ResetDuplicationModeAction {
  type: CalendarActionTypes.RESET_DUPLICATION_MODE;
}
export interface ToggleDuplicationModeAction {
  type: CalendarActionTypes.TOGGLE_DUPLICATION_MODE;
  payload: {
    dayIndex: number;
    duplicationMode: boolean;
    recordsToDuplicate: any[];
  };
}

export interface UpdateRecordToDuplicateListAction {
  type: CalendarActionTypes.UPDATE_RECORD_TO_DUPLICATE_LIST;
  payload: any[];
}

export type CalendarAction =
  | IncrementDateAction
  | DecrementDateAction
  | ToggleModalAction
  | FetchPlanningRequestAction
  | FetchPlanningSuccessAction
  | FetchPlanningFailureAction
  | FilterPlanningAction
  | SelectDateAction
  | ToggleFilterPanelAction
  | SetPlanningFiltersAction
  | ToggleDuplicationModeAction
  | ResetDuplicationModeAction
  | UpdateRecordToDuplicateListAction;
