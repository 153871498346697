import Airtable from "airtable";
import { Dayjs } from "dayjs";

const AirtableFetcher = (apiKey, base) => new Airtable({ apiKey }).base(base);

export default AirtableFetcher;

export const FetchPlanning = async (date: Dayjs) => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let planningEntries = [];

  let formula = "";

  
    formula = `AND(IS_BEFORE(Date,'${date
      .weekday(8)
      .format("YYYY-MM-DD")}'), IS_AFTER(Date,'${date
      .weekday(0)
      .subtract(1, "d")
      .format("YYYY-MM-DD")}'))`;
  

  await AirtableFetcher(
    apiKey,
    base
  )("Planning")
    .select({
      filterByFormula: formula,
      sort: [{ field: "Id", direction: "asc" }],
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      planningEntries = planningEntries.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });

  return planningEntries;
};

export const FetchDrivers = async () => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let drivers = [];
  await AirtableFetcher(
    apiKey,
    base
  )("Equipe")
    .select({
      // Selecting the first 3 records in Date:
      view: "Equipe",
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      drivers = drivers.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });
  drivers = drivers.map((d) => ({ id: d.id, fields: d.fields }));

  return drivers;
};

export const FetchVehicles = async () => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let vehicles = [];
  await AirtableFetcher(
    apiKey,
    base
  )("Matériel")
    .select({
      // Selecting the first 3 records in Date:
      view: "Camion",
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      vehicles = vehicles.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });

  vehicles = vehicles.map((v) => ({ id: v.id, fields: v.fields }));
  return vehicles;
};

export const FetchCustomers = async () => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let customers = [];
  await AirtableFetcher(
    apiKey,
    base
  )("Client")
    .select({
      // Selecting the first 3 records in Date:
      view: "Vue Simple",
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      customers = customers.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });

  customers = customers.map((c) => ({ id: c.id, fields: c.fields }));
  return customers;
};

export const FetchWorkSites = async () => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let workSites = [];
  await AirtableFetcher(
    apiKey,
    base
  )("Missillier TP")
    .select({
      // Selecting the first 3 records in Date:
      view: "Chantier",
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      workSites = workSites.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });

  workSites = workSites.map((c) => ({ id: c.id, fields: c.fields }));
  return workSites;
};

export const DeletePlanningItem = async (planningItems: any[]) => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  await AirtableFetcher(
    apiKey,
    base
  )("Planning").destroy(planningItems, function (err, deletedRecords) {
    if (err) {
      console.error(err);
      return;
    }
  });
};

export const FetchAbsenceCauses = async () => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  let absenceCauses = [];

  await AirtableFetcher(
    apiKey,
    base
  )("Absence")
    .select({
      // Selecting the first 3 records in Date:
      view: "Grid view",
    })
    .eachPage(function page(records: any, fetchNextPage: any) {
      absenceCauses = absenceCauses.concat(records);
      // To fetch the next page of records, call `fetchNextPage`.
      // If there are more records, `page` will get called again.
      // If there are no more records, `done` will get called.
      fetchNextPage();
    });

  absenceCauses = absenceCauses.map((c) => ({ id: c.id, fields: c.fields }));
  return absenceCauses;
};

export const CreatePlanningRecords = async (records) => {
  const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
  const base = localStorage.getItem("AIRTABLE_BASE");
  await AirtableFetcher(apiKey, base)("Planning").create(records);
};
