import { GlobalActionTypes } from "./global.types";

export interface GlobalState {
  appInit: boolean;
  drivers: any[];
  vehicles: any[];
  customers: [];
  workSites: any[];
  absenceCauses: any[];
}

export const initialGlobalState: GlobalState = {
  appInit: true,
  drivers: [],
  vehicles: [],
  customers: [],
  workSites: [],
  absenceCauses: [],
};

export const globalReducer = (
  state: GlobalState = initialGlobalState,
  action: any
): GlobalState => {
  switch (action.type) {
    case GlobalActionTypes.FETCH_DRIVERS: {
      return {
        ...state,
        drivers: action.payload,
      };
    }

    case GlobalActionTypes.FETCH_VEHICLES: {
      return {
        ...state,
        vehicles: action.payload,
      };
    }

    case GlobalActionTypes.FETCH_CUSTOMERS: {
      return {
        ...state,
        customers: action.payload,
      };
    }
    case GlobalActionTypes.FETCH_WORKSITES: {
      return {
        ...state,
        workSites: action.payload,
      };
    }
    case GlobalActionTypes.FETCH_ABSENCECAUSES: {
      return {
        ...state,
        absenceCauses: action.payload,
      };
    }

    case GlobalActionTypes.APP_INIT_COMPLETE: {
      return {
        ...state,
        appInit: false,
      };
    }
    default:
      return state;
  }
};
