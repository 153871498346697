import React, { useState, useEffect } from "react";
import { Formik } from "formik";
import Select from "react-select";

import {
  IonInput,
  IonList,
  IonItem,
  IonLabel,
  IonTextarea,
  IonButton,
  IonCheckbox,
  IonText,
  IonToast,
  IonSpinner,
  IonRow,
} from "@ionic/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../common/store";
import * as yup from "yup";
import "./PlanningForm.scss";
import AirtableFetcher from "../../helpers/Api";
import {
  PlanningEntryOptions,
  MISSILLIER_TP_CUSTOMER_ID,
  ABSENT_CUSTOMER_ID,
} from "../../common/constants";
import * as H from "history";
import { fetchPlanning } from "../../common/store/calendar/calendar.actions";
import { GlobalState } from "../../common/store/global/global.reducer";

interface PlanningFormProps {
  history?: H.History;
  record?: any;
  toggleEditing?: (toggleValue: boolean) => void;
}

const planningValidationSchema = yup.object().shape({
  Date: yup.date().nullable().required("Ce champ est requis"),
  Chauffeur: yup.array().of(yup.string()).nullable(),
  Camion: yup.array().of(yup.string()),
  Client: yup.array().of(yup.string()).required("Ce champ est requis"),
  Remarque: yup.string(),
  Matériel: yup.array().of(yup.string()),
  Options: yup.array().of(yup.string()),
  "Missillier TP": yup.array().of(yup.string()).nullable(),
  booking: yup.boolean(),
});

type PartialGlobalState = Pick<
  GlobalState,
  "drivers" | "customers" | "vehicles" | "workSites" | "absenceCauses"
>;

const newInitialValues = {
  Date: null,
  Chauffeur: [],
  Camion: [],
  Client: [],
  Absence: [],
  Remarque: "",
  Matériel: [],
  Options: [],
  "Missillier TP": [],
  booking: false,
  Réservation: null,
};

const PlanningForm: React.FunctionComponent<PlanningFormProps> = ({
  history,
  record,
  toggleEditing,
}) => {
  const dispatch = useDispatch();
  const {
    drivers,
    customers,
    vehicles,
    workSites,
    absenceCauses,
  }: PartialGlobalState = useSelector((state: RootState) => state.global);
  const [initialValues, setInitialValues] = useState<any>();
  const [showSuccessToast, setShowSuccessToast] = useState(false);
  const init = async () => {
    if (record && record !== null) {
      const initialValuesWithRecord = {
        Date: record.fields.Date,
        Chauffeur: [record.fields.Chauffeur?.id],
        Camion: [record.fields?.Camion?.id],
        Client: [record.fields.Client?.id],
        Absence: [record.fields?.Absence?.id],
        Remarque: record.fields.Remarque,
        Matériel: record.fields.Matériel.map((m) => m.id),
        Options: record.fields.Options,
        "Missillier TP": [record.fields["Missillier TP"]?.id],
        booking: Boolean(record.fields.Réservation),
        Réservation: null,
      };

      await setInitialValues(initialValuesWithRecord);
    } else {
      await setInitialValues(newInitialValues);
    }
  };

  useEffect(() => {
    init();
  }, []);

  // hacky way to reinit the form, so the rc-select components are getting (visually) empty
  const [uniqueKey, setUniqueKey] = useState(0);

  return (
    <div className={`form-container ${initialValues ? "" : "loading"}`}>
      {initialValues ? (
        <Formik
          enableReinitialize={true}
          initialValues={initialValues}
          onSubmit={async (values, actions) => {
            if (
              values.Camion === [] ||
              values.Camion[0] === null ||
              values.Camion[0] === undefined ||
              values.Camion === null ||
              values.Camion === undefined
            )
              delete values.Camion;
            if (
              values.Absence === [] ||
              values.Absence[0] === null ||
              values.Absence[0] === undefined
            )
              delete values.Absence;

            if (
              values["Missillier TP"] === [] ||
              values["Missillier TP"][0] === null ||
              values["Missillier TP"][0] === undefined
            )
              delete values["Missillier TP"];
            if (values.booking === true) {
              values.Réservation = "Résa";
              delete values.booking;
            } else {
              delete values.Réservation;
              delete values.booking;
            }

            if (values.Client?.[0] !== MISSILLIER_TP_CUSTOMER_ID) {
              values["Missillier TP"] = [];
            }
            if (values.Client?.[0] !== ABSENT_CUSTOMER_ID) {
              values.Absence = [];
            }
            const apiKey = localStorage.getItem("AIRTABLE_API_KEY");
            const base = localStorage.getItem("AIRTABLE_BASE");
            // Si on a pas de record c'est qu'on ajoute, alors on crée une nouvelle entrée
            if (!record) {
              try {
                await AirtableFetcher(apiKey, base)("Planning").create(values);
                actions.resetForm();
                actions.setValues(initialValues);
                setShowSuccessToast(true);
                history.replace("/calendar");
              } catch (error) {}
              dispatch(fetchPlanning());
              actions.setSubmitting(false);
            }
            // on a un record, alors on fait un update
            else {
              try {
                await AirtableFetcher(
                  apiKey,
                  base
                )("Planning").update([
                  { id: record.id, fields: { ...values } },
                ]);
                actions.resetForm();
                actions.setValues(initialValues);
                dispatch(fetchPlanning());
                setShowSuccessToast(true);
                actions.setSubmitting(false);
                toggleEditing(false);
              } catch (error) {
                console.error("error editing", error);
              }
            }
          }}
          validationSchema={planningValidationSchema}
        >
          {({
            handleBlur,
            handleChange,
            handleSubmit,
            values,
            touched,
            errors,
            dirty,
            setValues,
            resetForm,
            isSubmitting,
            setFieldTouched,
          }) => (
            <form onSubmit={handleSubmit} key={"form_" + uniqueKey}>
              <IonList>
                <IonItem>
                  <IonLabel position="stacked">
                    Date de début <IonText color="danger">*</IonText>
                  </IonLabel>
                  <IonInput
                    value={values.Date}
                    type="date"
                    name="Date"
                    placeholder={"Date de début"}
                    onIonChange={handleChange}
                    onIonBlur={handleBlur}
                  />
                </IonItem>
                {errors.Date && touched.Date && (
                  <div className="feedback">{errors.Date}</div>
                )}
                <IonRow>
                  <IonLabel position="stacked">
                    Chauffeur <IonText color="danger">*</IonText>
                  </IonLabel>
                  <Select
                    styles={customStyles}
                    isClearable
                    onBlur={handleBlur}    
                    placeholder="Chauffeur"
                    name="Chauffeur"
                    value={drivers
                      .map((driver: any) => ({
                        value: driver?.id,
                        label: driver?.fields?.Prénom,
                      })).find((d) => d.value === values.Chauffeur?.[0])
                    }
                    options={drivers.map((driver: any) => ({
                      value: driver?.id,
                      label: driver?.fields?.Prénom,
                    }))}
                    
                    onChange={(e: any) => {
                      if (e !== null) {
                        setValues({
                          ...values, 
                          Chauffeur: [e.value]})
                      }
                      else {
                        setValues({
                          ...values,
                          Chauffeur: null
                        })
                      }
                    }}                    
                  />
                </IonRow>
                {errors.Chauffeur && touched.Chauffeur && (
                  <div className="feedback">{errors.Chauffeur}</div>
                )}

                <IonRow>
                  <IonLabel position="stacked">Camion</IonLabel>
                  <Select
                    styles={customStyles}
                    onBlur={handleBlur}                    
                    placeholder="Camion"
                    name="Camion"
                    options={vehicles.map((vehicle: any) => {
                      return {
                        value: vehicle?.id,
                        label: vehicle?.fields?.Identifiant,
                      };
                    })}
                    value={vehicles
                      .map((vehicle: any) => ({
                        value: vehicle?.id,
                        label: vehicle.fields.Identifiant,
                      }))
                      .find((v) => v.value === values.Camion?.[0])}

                    onChange={(e: any) => {
                        setValues({
                          ...values, 
                          Camion:[e.value],
                        });
                    }}
                  />
                </IonRow>
                
                {errors.Camion && touched.Camion && (
                  <div className="feedback">{errors.Camion}</div>
                )}
                <IonRow>
                  <IonLabel position="stacked">
                    Client <IonText color="danger">*</IonText>
                  </IonLabel>
                  <Select
                    styles={customStyles}
                    onBlur={handleBlur}
                    
                    placeholder="Client"
                    name="Client"
                    options={customers.map((customer: any) => ({
                      value: customer?.id,
                      label: customer?.fields?.Client,
                    }))}
                    value={customers
                      .map((customer: any) => ({
                        value: customer?.id,
                        label: customer?.fields?.Client,
                      }))
                      .find((c) => c.value === values.Client?.[0])}
                    onChange={(e: any) => {                        
                      let updatedValues = { ...values };
                        updatedValues.Client = [e.value];
                        if (values.Client[0] === ABSENT_CUSTOMER_ID)
                          updatedValues.Absence = [];

                        if (values.Client[0] === MISSILLIER_TP_CUSTOMER_ID)
                          updatedValues["Missillier TP"] = [];

                        setValues(updatedValues);
                      
                    }}
                  />
                </IonRow>
                {errors.Client && touched.Client && (
                  <div className="feedback">{errors.Client}</div>
                )}

                {MISSILLIER_TP_CUSTOMER_ID === values.Client?.[0] && (
                  <IonRow>
                    <IonLabel position="stacked">Missillier TP</IonLabel>
                    <Select
                      styles={customStyles}
                      onBlur={handleBlur}
                      placeholder="Chantier MTP"
                      name="Chantier MTP"
                      options={workSites.map((workSite: any) => {
                        return {
                          value: workSite?.id,
                          label: workSite?.fields?.Chantier,
                        };
                      })}
                      value={workSites
                        .map((workSite: any) => ({
                          value: workSite?.id,
                          label: workSite?.fields?.Chantier,
                        }))
                        .find(
                          (ws) =>
                            ws.value === values?.["Missillier TP"]?.[0]
                        )}

                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          "Missillier TP": [e.value],
                        });
                      }}
                    />
                  </IonRow>
                )}
                {errors["Missillier TP"] && touched["Missillier TP"] && (
                  <div className="feedback">{errors["Missillier TP"]}</div>
                )}

                {ABSENT_CUSTOMER_ID === values.Client?.[0] && (
                  <IonRow>
                    <IonLabel position="stacked">Raison absence</IonLabel>
                    <Select
                      styles={customStyles}
                      onBlur={handleBlur}
                      placeholder="Motif Absence"
                      value={absenceCauses
                        .map((absenceCause: any) => ({
                          value: absenceCause?.id,
                          label: absenceCause?.fields?.Nom,
                        }))
                        .find((c) => c.value === values.Absence?.[0])}
                      options={absenceCauses.map((absenceCause: any) => {
                        return {
                          value: absenceCause?.id,
                          label: absenceCause?.fields?.Nom,
                        };
                      })}
                      onChange={(e: any) => {
                        setValues({
                          ...values,
                          Absence: [e.value],
                        });
                      }}
                    />
                  </IonRow>
                )}
                {errors["Absence"] && touched["Absence"] && (
                  <div className="feedback">{errors["Absence"]}</div>
                )}

                <IonItem>
                  <IonLabel position="stacked">Remarques</IonLabel>
                  <IonTextarea
                    name="Remarque"
                    placeholder="Remarques"
                    value={values.Remarque}
                    onIonChange={handleChange}
                  ></IonTextarea>
                </IonItem>
                {errors.Remarque && touched.Remarque && (
                  <div className="feedback">{errors.Remarque}</div>
                )}
                <IonRow>
                  <IonLabel position="stacked">Matériel</IonLabel>
                  <Select
                    styles={customStyles}
                    onBlur={handleBlur}
                    isMulti
                    placeholder="Matériel"
                    options={vehicles.map((vehicle: any) => {
                      return {
                        value: vehicle?.id,
                        label: vehicle?.fields?.Identifiant,
                      };
                    })}
                    value={vehicles
                      .map((vehicle: any) => ({
                        value: vehicle?.id,
                        label: vehicle?.fields?.Identifiant,
                      }))
                      .filter((v) =>
                        values?.["Matériel"].includes(v.value)
                      )}

                    onChange={(e: any) => {
                      if (e !== null)
                        setValues({
                          ...values,
                          ["Matériel"]: e.map((mat) => mat.value),
                        });
                      else
                        setValues({
                          ...values,
                          Matériel: [],
                        });
                    }}
                  />
                </IonRow>
                {errors["Matériel"] && touched["Matériel"] && (
                  <div className="feedback">{errors["Matériel"]}</div>
                )}
                <IonRow>
                  <IonLabel position="stacked">Options</IonLabel>

                  <Select
                    isMulti
                    styles={customStyles}
                    onBlur={handleBlur}
                    placeholder="Options"
                    options={PlanningEntryOptions.map((opt: any) => {
                      return {
                        value: opt,
                        label: opt,
                      };
                    })}
                    value={(values?.Options || []).map((opt) => ({
                      value: opt,
                      label: opt,
                    }))}

                    onChange={(e: any) => {
                      setValues({
                        ...values,
                        Options: e.map((opt) => opt.value),
                      });
                    }}
                  />
                </IonRow>
                <IonItem>
                  <IonLabel>Réservation</IonLabel>
                  <IonCheckbox
                    name="booking"
                    slot="start"
                    onIonChange={(e: any) => {
                      setValues({
                        ...values,
                        booking: e.detail.checked,
                      });
                    }}
                    checked={values.booking}
                  />
                </IonItem>
                <IonButton type="submit" disabled={isSubmitting || !dirty}>
                  Enregistrer
                </IonButton>
                <IonButton
                  color="danger"
                  onClick={async (e: any) => {
                    setUniqueKey(uniqueKey + 1);
                    resetForm();
                  }}
                >
                  Réinitialiser
                </IonButton>
              </IonList>
              <IonToast
                color="success"
                isOpen={showSuccessToast}
                onDidDismiss={() => setShowSuccessToast(false)}
                message="Element ajouté au planning"
                duration={100}
              />
              {/* <DisplayFormikState
                values={values}
                touched={touched}
                errors={errors}
              /> */}
            </form>
          )}
        </Formik>
      ) : (
        <IonSpinner />
      )}
    </div>
  );
};
export default PlanningForm;

export const DisplayFormikState = ({ values, touched, errors }) => (
  <div style={{ margin: "1rem 0", background: "#f6f8fa", padding: ".5rem" }}>
    <strong>Injected Formik props (the form's state)</strong>
    <div style={{}}>
      <code>touched:</code> {JSON.stringify(touched, null, 2)}
    </div>
    <div>
      <code>errors:</code> {JSON.stringify(errors, null, 2)}
    </div>
    <div>
      <code>values:</code> {JSON.stringify(values, null, 2)}
    </div>
    <div>
      {/* <code>isSubmitting:</code> {JSON.stringify(props.isSubmitting, null, 2)} */}
    </div>
  </div>
);

const customStyles = {
  // Fixes the overlapping problem of the component
  input: (provided) => ({
    ...provided,
    minWidth: "200px",
  }),
  menu: (provided) => ({ ...provided, zIndex: 9999 }),
};
