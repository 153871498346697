import React, { useCallback, useState } from "react";
import {
  IonLabel,
  IonButton,
  IonModal,
  IonBadge,
  IonContent,
  IonButtons,
  IonChip,
  IonAlert,
} from "@ionic/react";

import LicensePlate from "../../LicensePlate/LicensePlate";
import { DeletePlanningItem } from "../../../helpers/Api";
import { RootState } from "../../../common/store";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleModal,
  fetchPlanning,
  duplicateRecordList,
} from "../../../common/store/calendar/calendar.actions";
import PlanningForm from "../../PlanningForm/PlanningForm";
import dayjs from "dayjs";

import "./PlanningSlotModal.scss";
import { CalendarState } from "../../../common/store/calendar/calendar.reducer";

type PlanningSlotModalProps = {};

type PartialCalendarState = Pick<
  CalendarState,
  "currentRecord" | "showPlanningModal"
>;

const PlanningSlotModal: React.FunctionComponent<PlanningSlotModalProps> = () => {
  const {
    currentRecord: record,
    showPlanningModal,
  }: PartialCalendarState = useSelector((state: RootState) => state.calendar);

  const dispatch = useDispatch();
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [showDuplicationAlert, setDuplicationAlert] = useState(false);
  const [editable, setEditable] = useState(false);

  const deleteItem = async () => {
    await DeletePlanningItem([record.id]);
    dispatch(toggleModal(false, null));
    dispatch(fetchPlanning());

    setShowDeleteAlert(false);
    dispatch(toggleModal(false, null));
  };

  const duplicateItem = async (date) => {
    dispatch(duplicateRecordList(date, [record]));
    dispatch(toggleModal(false, null));
  };

  const displayDeleteConfirmationModal = useCallback(() => {
    setShowDeleteAlert(true);
  }, []);

  const onEditClick = useCallback(() => {
    setEditable(true);
  }, []);

  const onDuplicateClick = useCallback(() => {
    setDuplicationAlert(true);
  }, []);

  const onCloseClick = useCallback(() => {
    setEditable(false);
    dispatch(toggleModal(false, null));
  }, []);

  if (!record || record === null) return null;

  if (showPlanningModal)
    return (
      <IonModal
        isOpen={showPlanningModal}
        onDidDismiss={() => {
          dispatch(toggleModal(false, null));
          setEditable(false);
        }}
        cssClass="modal-slot"
      >
        <header>
          <IonButtons slot="end">
            <IonButton
              color="danger"
              fill="outline"
              onClick={displayDeleteConfirmationModal}
            >
              Supprimer
            </IonButton>
            <IonButton color="success" fill="outline" onClick={onEditClick}>
              Modifier
            </IonButton>
            <IonButton
              color="secondary"
              fill="outline"
              onClick={onDuplicateClick}
            >
              Dupliquer
            </IonButton>
            <IonButton fill="solid" color="primary" onClick={onCloseClick}>
              Fermer
            </IonButton>
          </IonButtons>
          <div className="info-slot">
            {record?.fields?.["Réservation"] === "Résa" && (
              <IonBadge color="primary">Réservation</IonBadge>
            )}
            <h1>{record.fields.Chauffeur?.fields?.["Prénom"]}</h1>
            <time>{dayjs(record.fields.Date).format("ddd DD MMMM")}</time>
          </div>
        </header>
        <IonContent>
          {!editable ? (
            <section className="slot-content">
              {(record.fields?.Camion ||
                record.fields.Matériel?.length > 0) && (
                <section>
                  <h2>Matériels</h2>

                  {record.fields?.Camion?.fields?.Immatriculation && (
                    <article>
                      <h3>Camion</h3>
                      <LicensePlate
                        license={record.fields?.Camion?.fields?.Immatriculation}
                      />
                    </article>
                  )}

                  {record.fields?.Camion?.fields?.Nom === "#" && (
                    <article>
                      <h3>Camion</h3>
                      Pas de camion
                    </article>
                  )}
                  <article>
                    {record.fields.Matériel?.length > 0 && (
                      <div>
                        <h3>Avec</h3>
                        {record.fields.Matériel.map((m) => {
                          return (
                            <IonChip
                              color="primary"
                              key={m.id}
                              className="not-clickable"
                            >
                              <IonLabel>{m.fields.Identifiant}</IonLabel>
                            </IonChip>
                          );
                        })}
                      </div>
                    )}
                  </article>
                </section>
              )}
              <section className="chantier">
                <h2>Chantier</h2>
                <article>
                  <IonChip
                    className="chantier-nom"
                    style={{
                      backgroundColor: `#${record?.fields?.Client?.fields?.Couleur}`,
                    }}
                  >
                    {record.fields.Client && record.fields.Client.fields.Client}
                  </IonChip>
                  {record.fields?.Absence && (
                    <article>
                      <h3>Motif Absence:</h3>
                      {record.fields?.Absence?.fields?.Nom}
                    </article>
                  )}
                  {record.fields?.["Missillier TP"] && (
                    <article>
                      <h3>Chantier MTP</h3>
                      {record.fields["Missillier TP"].fields.Chantier}
                    </article>
                  )}
                </article>
                {record.fields.Options?.length > 0 && (
                  <article>
                    <h3>Options</h3>
                    {record.fields.Options.map((opt) => (
                      <IonChip
                        color="primary"
                        key={opt}
                        className="not-clickable"
                      >
                        <IonLabel>{opt}</IonLabel>
                      </IonChip>
                    ))}
                  </article>
                )}
              </section>
              {record.fields.Remarque?.length > 0 && (
                <section>
                  <article>
                    <h3>Remarque</h3>
                    {record.fields.Remarque}
                  </article>
                </section>
              )}
            </section>
          ) : (
            <PlanningForm
              record={record}
              toggleEditing={() => {
                setEditable(false);
              }}
            />
          )}
          <IonAlert
            isOpen={showDeleteAlert}
            onDidDismiss={() => setShowDeleteAlert(false)}
            header={"Confirm!"}
            message={`Êtes vous sûr de vouloir supprimer l'élément?`}
            buttons={[
              {
                text: "Annuler",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  setShowDeleteAlert(false);
                },
              },
              {
                text: "Supprimer",
                handler: () => {
                  deleteItem();
                },
              },
            ]}
          />
          <IonAlert
            isOpen={showDuplicationAlert}
            onDidDismiss={() => setDuplicationAlert(false)}
            header={"Date de destination"}
            message={`Indiquez la date à laquelle dupliquer l'élément.`}
            inputs={[
              {
                name: "date",
                type: "date",
                placeholder: "Date de destination",
                //min: dayjs().format("YYYY-MM-DD"),
                value: dayjs().add(1, "d").format("YYYY-MM-DD"),
              },
            ]}
            buttons={[
              {
                text: "Annuler",
                role: "cancel",
                cssClass: "secondary",
                handler: () => {
                  setDuplicationAlert(false);
                },
              },
              {
                text: "Dupliquer",
                handler: (params) => {
                  duplicateItem(params.date);
                },
              },
            ]}
          />
        </IonContent>
      </IonModal>
    );
};
export default React.memo(PlanningSlotModal);
