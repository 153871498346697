import React from "react";
import {
  IonLabel,
  IonItem,
  IonBadge,
  IonChip,
  IonCheckbox,
} from "@ionic/react";
import { PlanningRecord } from "./PlanningRecord.interface";
import { useDispatch, useSelector } from "react-redux";
import { toggleModal, updateRecordListToDuplicate } from "../../../common/store/calendar/calendar.actions";

import "./PlanningSlot.scss";
import { RootState } from "../../../common/store";
interface PlanningSlotProps {
  record: PlanningRecord;
  duplicationModeEnabled: boolean;
}

const PlanningSlot: React.FunctionComponent<PlanningSlotProps> = ({
  record,
  duplicationModeEnabled,
}) => {
  const dispatch = useDispatch();
  const recordsToDuplicate = useSelector(
    (state: RootState) => state.calendar.recordsToDuplicate
  );

  return (
    <IonItem
      lines="none"
      button
      onClick={() => {
        !duplicationModeEnabled &&
        dispatch(toggleModal(true, record));
      }}
      className="day-slot"
    >
      {record.fields["Réservation"] === "Résa" && (
        <IonBadge color="primary">R </IonBadge>
      )}
      <IonLabel className="day-slot-label">
        <strong>{record.fields.Chauffeur?.fields?.["Prénom"]}</strong>{" "}
        {record.fields?.Camion && (
          <>- {record.fields?.Camion?.fields?.Immatriculation}</>
        )}
        
        {record.fields["Réservation"] === "Résa" && (<> {record.fields.Remarque}</>)}
        <IonChip
          style={{
            backgroundColor: `#${record?.fields?.Client?.fields?.Couleur}`,
          }}
        >
          {record.fields?.Client && record.fields?.Client.fields?.Client}
        </IonChip>
      </IonLabel>
      {duplicationModeEnabled === true && (
        <IonCheckbox
          name="booking"
          slot="start"
          onIonChange={(e) => {
            dispatch(updateRecordListToDuplicate(record, e.detail.checked))
          }}
          checked={recordsToDuplicate.map((r) => r.id).includes(record.id)}
        />
      )}
    </IonItem>
  );
};
export default PlanningSlot;
