import React, { useCallback, useState } from "react";

import dayjs from "dayjs";

import { IonIcon, IonPopover } from "@ionic/react";
import { chevronBackOutline, chevronForwardOutline } from "ionicons/icons";
import "./DateSelector.scss";
import { RootState } from "../../common/store";
import { useDispatch, useSelector } from "react-redux";
import DatePicker, { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import fr from "date-fns/locale/fr";
import {
  incrementDate,
  decrementDate,
  selectDate,
} from "../../common/store/calendar/calendar.actions";
import {
  CalendarState,} from "../../common/store/calendar/calendar.reducer";

type DateSelectorProps = {};

type PartialCalendarState = Pick<CalendarState, "currentDate">;

registerLocale("fr", fr);

const DateSelector: React.FunctionComponent<DateSelectorProps> = () => {
  const dispatch = useDispatch();
  const { currentDate }: PartialCalendarState = useSelector(
    (state: RootState) => state.calendar
  );
  const [calendarModalOpened, setModalOpened] = useState(false);

  const openModal = useCallback(() => setModalOpened(true), []);
  return (
    <div className="date-selector-container">
      <p>
        <IonIcon
          icon={chevronBackOutline}
          onClick={useCallback(() => dispatch(decrementDate()), [dispatch])}
        />
      </p>
      
        <p className="date" onClick={openModal}>
          {`Semaine du ${dayjs(currentDate)
            .weekday(0)
            .format("D MMMM")} au ${dayjs(currentDate)
            .weekday(6)
            .format("D MMMM YYYY")}`}{" "}
        </p>
      <p>
        <IonIcon
          icon={chevronForwardOutline}
          onClick={useCallback(() => dispatch(incrementDate()), [dispatch])}
        />
      </p>

      <IonPopover
        cssClass="calendar"
        isOpen={calendarModalOpened}
        onWillDismiss={() => setModalOpened(false)}
      >
        <DatePicker
          selected={currentDate.toDate()}
          onChange={(date) => {
            dispatch(selectDate(date));
            setModalOpened(false);
          }}
          inline
          locale={fr}
        />
      </IonPopover>
    </div>
  );
};

export default DateSelector;
