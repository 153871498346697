import { createStore, combineReducers, applyMiddleware } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { CalendarState, calendarReducer } from "./calendar/calendar.reducer";
import { globalReducer, GlobalState } from "./global/global.reducer";
import thunkMiddleware from "redux-thunk";
export interface RootState {
  calendar: CalendarState;
  global: GlobalState;
}
const rootReducer = combineReducers<RootState>({
  calendar: calendarReducer,
  global: globalReducer,
});

export default function configureStore() {
  const middlewares = [thunkMiddleware];
  const middleWareEnhancer = applyMiddleware(...middlewares);

  const store = createStore(
    rootReducer,
    composeWithDevTools(middleWareEnhancer)
  );

  return store;
}
