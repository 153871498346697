import React from "react";
import PlanningSlot from "./PlanningSlot/PlanningSlot";
import PlanningSlotLoader from "./PlanningSlotLoader/PlanningSlotLoader";
import { IonItem, IonLabel } from "@ionic/react";
import { useSelector } from "react-redux";
import { RootState } from "../../common/store";

type DailyViewProps = {
  dailyPlanning: any[];
  loadingStatus: boolean;
  dayIndex?: number;
};

const DailyView: React.FunctionComponent<DailyViewProps> = ({
  dailyPlanning,
  loadingStatus,
  dayIndex,
}) => {
  const { duplicationMode, dayIndexToDuplicate } = useSelector(
    (state: RootState) => state.calendar
  );

  const isDuplicationModeEnabled = (): boolean => {
    
    if (!duplicationMode) return false;

    // Ca veut dire qu'on est en "Vue Jour" donc tous les records sont cochés comme il n'y a qu'un jour
    if (dayIndex === undefined) return true;

    if (dayIndexToDuplicate === dayIndex) return true;

    return false;
  };

  return (
    <>
      {loadingStatus ? (
        [1, 2, 3, 4, 5, 6].map((p) => <PlanningSlotLoader key={"loader" + p} />)
      ) : dailyPlanning.length > 0 ? (
        dailyPlanning.map((slot) => (
          <PlanningSlot
            key={slot.id}
            record={slot}
            duplicationModeEnabled={isDuplicationModeEnabled()}
          />
        ))
      ) : (
        <IonItem className="day-slot" lines="none">
          <IonLabel className="day-slot-label">
            Pas d'entrée aujourd'hui
          </IonLabel>
        </IonItem>
      )}
    </>
  );
};
export default DailyView;
