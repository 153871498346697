import { IonButton, IonInput, IonRow } from "@ionic/react";
import dayjs from "dayjs";
import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { animated, config, useSpring } from "react-spring";
import { RootState } from "../../common/store";
import {
  duplicateRecordList,
  toggleDuplicationMode,
} from "../../common/store/calendar/calendar.actions";
import "./DuplicateModal.scss";
type DuplicateModalProps = {};

const DuplicateModal: React.FunctionComponent<DuplicateModalProps> = () => {
  const { duplicationMode, recordsToDuplicate } = useSelector(
    (state: RootState) => state.calendar
  );
  const dispatch = useDispatch();
  const [destinationDate, setDate] = useState(null);

  const onDestinationDateChange = useCallback((e) => {
    setDate(e.detail.value);
  }, []);
  const contentProps = useSpring({
    from: { opacity: 0, marginTop: -60 },
    opacity: duplicationMode ? 1 : 0,
    marginTop: duplicationMode ? 0 : -60,
    config: config.default,
  });

  if (!duplicationMode) return null;

  return (
    <animated.div style={contentProps} className="duplicate">
      <p className="duplicate__title">Dupliquer des éléments de planning</p>

      <IonRow className="duplicate__inner ion-align-items-center">
        {recordsToDuplicate.length === 0
          ? "Selectionner des entrées de planning"
          : `Dupliquer ${recordsToDuplicate.length} ${
              recordsToDuplicate.length > 1 ? "entrées" : "entrée"
            } de planning
        vers la date du `}
        {recordsToDuplicate.length > 0 && (
          <IonInput
            type="date"
            name="Date de destination"
            //min={dayjs().format("YYYY-MM-DD")}
            placeholder={"Date de destination"}
            onIonChange={onDestinationDateChange}
          />
        )}
      </IonRow>

      <IonRow ><IonButton
        color="danger"
        fill="outline"
        onClick={() => {
          dispatch(toggleDuplicationMode(null, false));
        }}
      >
        Annuler
      </IonButton>
      {destinationDate !== null && destinationDate !== "" && (
        <IonButton color="success"
          onClick={() => {
            dispatch(duplicateRecordList(destinationDate));
          }}
        >
          Dupliquer
        </IonButton>
      )}</IonRow>
    </animated.div>
  );
};

export default DuplicateModal;
