import {
  IonButtons,
  IonHeader,
  IonMenuButton,
  IonPage,
  IonToolbar,
  
  IonRow,
  IonIcon,
  IonFabButton,
  IonFab,
  IonGrid,
  IonCol,
  IonButton,
} from "@ionic/react";
import React from "react";
import DateSelector from "../../components/DateSelector/DateSelector";
import Planning from "../../components/Planning/Planning";
import { useDispatch } from "react-redux";
import {
  selectDate,
} from "../../common/store/calendar/calendar.actions";
import "./CalendarPage.scss";
import { getPlatforms } from "@ionic/react";
import { add } from "ionicons/icons";
import PlanningFilters from "../../components/PlanningFilters/PlanningFilters";
import PlanningFiltersDisplay from "../../components/PlanningFilters/PlanningFiltersDisplay";
import PlanningSlotModal from "../../components/Planning/PlanningSlot/PlanningSlotModal";
import DuplicateModal from "../../components/DuplicateModal/DuplicateModal";

type CalendarPageProps = {};

const CalendarPage: React.FC<CalendarPageProps> = () => {
  const dispatch = useDispatch();
  return (
    <IonPage>
      <IonHeader mode="ios">
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton />
          </IonButtons>
          <IonGrid>
            <IonRow>
              <IonCol size={getPlatforms().includes("mobile") ? "12" : "4"}>
                <DateSelector />
              </IonCol>
              <IonCol size={getPlatforms().includes("mobile") ? "12" : "4"} >
                <IonRow class="ion-justify-content-center">
                  <IonButton
                    size="small"
                    onClick={() => {
                      dispatch(selectDate(new Date()));
                    }}
                  >
                    Aujourd'hui
                  </IonButton>
                 
                </IonRow>
              </IonCol>

              {!getPlatforms().includes("mobile") && <PlanningFilters />}
            </IonRow>
          </IonGrid>
        </IonToolbar>
      </IonHeader>

      {/* <IonContent> */}
      {!getPlatforms().includes("mobile") && <PlanningFiltersDisplay />}
      <DuplicateModal />
      <div className="planning-outer-container">
        <Planning />
        <PlanningSlotModal />
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton routerLink={"/calendar/new"} routerDirection="forward">
            <IonIcon icon={add} />
          </IonFabButton>
        </IonFab>
        {/* </IonContent> */}
      </div>
    </IonPage>
  );
};

export default React.memo(CalendarPage);
