import React from "react";
import "./LicensePlate.scss";
interface LicensePlateProps {
  license: string;
}

const LicensePlate: React.FunctionComponent<LicensePlateProps> = ({
  license
}) => {
  return <p className="licenseplate">{license}</p>;
};
export default LicensePlate;
