import { RootState } from "./store";
import { PlanningRecord } from "../components/Planning/PlanningSlot/PlanningRecord.interface";

export const buildDailyData = (record, state: RootState) => {
  const {
    absenceCauses,
    vehicles,
    drivers,
    customers,
    workSites,
  } = state.global;
  let updatedRecord: PlanningRecord = {
    id: record.id,
    fields: { ...record.fields, Matériel: [] },
  };

  // On remplace l'id du chauffeur par le record lui même
  if (record.fields.Chauffeur)
    updatedRecord.fields.Chauffeur = drivers.find(
      (d) => record.fields.Chauffeur[0] === d.id
    );

  // idem pour le camion et le matériel (peut avoir plusieurs matériels)
  if (record.fields.Camion || record.fields.Matériel) {
    vehicles.map((v) => {
      if (record.fields.Camion && record.fields.Camion[0] === v.id)
        updatedRecord.fields.Camion = v;

      if (record.fields.Matériel && record.fields.Matériel.includes(v.id))
        updatedRecord.fields.Matériel.push(v);
      return v;
    });
  }
  // idem clients
  if (record.fields.Client)
    updatedRecord.fields.Client = customers.find((c: any) => {
      return record.fields.Client[0] === c.id;
    });

  // idem pour le chantier Missillier TP
  if (record.fields["Missillier TP"])
    updatedRecord.fields["Missillier TP"] = workSites.find(
      (w) => record.fields["Missillier TP"][0] === w.id
    );

  if (record.fields.Absence) {
    updatedRecord.fields.Absence = absenceCauses.find((c: any) => {
      return record.fields.Absence[0] === c.id;
    });
  }

  return updatedRecord;
};

export const filterDay = (day: any[], filters) => {
  return day.filter((item) => {
    let customerFilter = false;
    let driverFilter = false;
    let vehicleFilter = false;
    let bookingFilter = false;

    if (filters.customers && filters.customers.length) {
      customerFilter = filters.customers
        .map((c) => c.value)
        .includes(item.fields.Client.id);
    } else {
      customerFilter = true;
    }
    if (filters.drivers && filters.drivers.length) {
      driverFilter = filters.drivers
        .map((d) => d.value)
        .includes(item.fields.Chauffeur.id);
    } else {
      driverFilter = true;
    }
    if (filters.vehicles && filters.vehicles.length) {
      vehicleFilter =
        filters.vehicles.includes(item.fields.Camion?.id) ||
        item.fields.Matériel?.some((mat) => filters.vehicles.includes(mat.id));
    } else {
      vehicleFilter = true;
    }

    if (filters.booking) {
      if (item.fields.Réservation) {
        bookingFilter = true;
      } else {
        bookingFilter = false;
      }
    }
    // Si le filtre n'est booking on inclue TOUT
    else {
      bookingFilter = true;
    }

    return customerFilter && driverFilter && vehicleFilter && bookingFilter;
  });
};

export const prepareDataToDuplicate = (recordList: any[], date: string) => {
  return recordList.map((rec) => {
    let newRecord : any= {
      fields: {
        Absence: [],
        Camion: [],
        Chauffeur: [],
        Client: [],
        Matériel: [],
        Date: "",
      },
    };
    if (rec.fields["Missillier TP"]) 
      newRecord.fields["Missillier TP"] = [rec.fields["Missillier TP"].id];
    if (rec.fields.Camion) {
      if (rec.fields.Camion.id)
        newRecord.fields.Camion = [rec.fields.Camion?.id];
      else if (rec.fields.Camion !== null)
        newRecord.fields.Camion = rec.fields.Camion;
    }

    if (rec.fields.Chauffeur)
      newRecord.fields.Chauffeur = [rec.fields.Chauffeur.id];

    if (rec.fields.Client) newRecord.fields.Client = [rec.fields.Client.id];
    if (rec.fields.Réservation) newRecord.fields.Réservation = rec.fields.Réservation;

    if (rec.fields.Absence) newRecord.fields.Absence = [rec.fields.Absence.id];

    if (rec.fields.Matériel)
      newRecord.fields.Matériel = rec.fields.Matériel.map((m) => m.id);
      if (rec.fields.Options)
        newRecord.fields.Options = rec.fields.Options.map((o) => o);

    newRecord.fields.Date = date;
    return newRecord;
  });
};

// Function to split an array
export function* chunks(arr, n) {
  for (let i = 0; i < arr.length; i += n) {
    yield arr.slice(i, i + n);
  }
}
