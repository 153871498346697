import React, { useState } from "react";

import { IonModal, IonItem, IonLabel, IonInput, IonButton } from "@ionic/react";

interface ModalConfigurationProps {
  showModal: boolean;
  setModalInit: (toggle: boolean) => void;
}

const ModalConfiguration: React.FunctionComponent<ModalConfigurationProps> = ({
  showModal,
  setModalInit,
}) => {
  const [apiKey, setApiKey] = useState("");
  const [base, setBase] = useState("");
  return (
    <IonModal isOpen={showModal} backdropDismiss={false}>
      <IonItem>
        <IonLabel position="stacked">Clé d'Api</IonLabel>
        <IonInput
          value={apiKey}
          onIonChange={(e) => setApiKey(e.detail.value)}
        />
      </IonItem>
      <IonItem>
        <IonLabel position="stacked">Base</IonLabel>
        <IonInput value={base} onIonChange={(e) => setBase(e.detail.value)} />
      </IonItem>
      <IonButton
        onClick={() => {
          if (apiKey !== "" && base !== "") {
            localStorage.setItem("AIRTABLE_API_KEY", apiKey);
            localStorage.setItem("AIRTABLE_BASE", base);
            setModalInit(false);
          }
        }}
      >
        Valider
      </IonButton>
    </IonModal>
  );
};

export default ModalConfiguration;
