import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonMenu,
  IonMenuToggle,
  IonTitle,
  isPlatform,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import {
  addCircleSharp,
  personAddSharp,
  createSharp,
  carSharp,
  homeSharp,
} from "ionicons/icons";
import "./Menu.css";
import PlanningFiltersDisplay from "../PlanningFilters/PlanningFiltersDisplay";

interface MenuProps extends RouteComponentProps {
  selectedPage: string;
}

interface AppPage {
  url: string;
  iosIcon: string;
  mdIcon: string;
  title: string;
  type: "internal" | "external";
}

const appPages: AppPage[] = [
  {
    title: "Ajouter une entrée",
    url: "/calendar/new",
    iosIcon: addCircleSharp,
    mdIcon: addCircleSharp,
    type: "internal",
  },
  {
    title: "Client - Ajout",
    url: "https://airtable.com/tblhxEjqqrC3eAK8h/viwNAhnZNoiDYy4yW?blocks=hide",
    iosIcon: personAddSharp,
    mdIcon: personAddSharp,
    type: "external",
  },
  {
    title: "Chantier MTP - Ajout",
    url: "https://airtable.com/tblAjVDvjkulZ21Nu/viwqK7L2CuQO9ZXGY?blocks=hide",
    iosIcon: homeSharp,
    mdIcon: homeSharp,
    type: "external",
  },
  {
    title: "Matériel - Ajout",
    url: "https://airtable.com/tblh4g7wSBC3jbw2O/viwH24IOL9RMbDxsk?blocks=hide",
    iosIcon: carSharp,
    mdIcon: carSharp,
    type: "external",
  },
  {
    title: "Gestion - Client",
    url: "https://airtable.com/tblxXTiF8fgHrlbXM/viwlmuGGQxkb7p5B9?blocks=hide",
    iosIcon: createSharp,
    mdIcon: createSharp,
    type: "external",
  },
];

const Menu: React.FunctionComponent<MenuProps> = ({ selectedPage }) => {
  return (
    <IonMenu contentId="main" type="reveal">
      <IonContent>
        <IonTitle>Transmis Dispatch</IonTitle>
        <IonList id="inbox-list">
          {appPages.map((appPage, index) => {
            if (appPage.type === "internal")
              return (
                <IonMenuToggle key={index} autoHide={false}>
                  <IonItem
                    className={selectedPage === appPage.title ? "selected" : ""}
                    routerLink={appPage.url}
                    routerDirection="none"
                    lines="none"
                    detail={false}
                  >
                    <IonIcon slot="start" icon={appPage.iosIcon} />
                    <IonLabel>{appPage.title}</IonLabel>
                  </IonItem>
                </IonMenuToggle>
              );
            else
              return (
                <IonItem lines="none" detail={false} key={index}>
                  <IonIcon slot="start" icon={appPage.iosIcon} />
                  <a
                    href={appPage.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <IonLabel>{appPage.title}</IonLabel>
                  </a>
                </IonItem>
              );
          })}
        </IonList>
        {isPlatform("mobile") && <PlanningFiltersDisplay forceToggle={true} />}
      </IonContent>
    </IonMenu>
  );
};

export default withRouter(Menu);
