import {
  IonButtons,
  IonContent,
  IonHeader,
  IonPage,
  IonTitle,
  IonToolbar,
  IonBackButton,
} from "@ionic/react";
import React from "react";
import { RouteComponentProps } from "react-router";
import PlanningForm from "../../components/PlanningForm/PlanningForm";

type PlanningFormPageProps = RouteComponentProps<{ name: string }>;

const PlanningFormPage: React.FC<PlanningFormPageProps> = ({ history }) => {
  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonBackButton defaultHref="/calendar" />
          </IonButtons>
          <IonTitle>Ajouter un évenement</IonTitle>
        </IonToolbar>
      </IonHeader>
      {/* <IonHeader>
        <IonToolbar>
        <IonButtons slot="start">
            <IonMenuButton />
            </IonButtons>
        </IonToolbar>
      </IonHeader> */}

      <IonContent fullscreen>
        <PlanningForm history={history} />
      </IonContent>
    </IonPage>
  );
};

export default PlanningFormPage;
